/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Notes,
  SaveNotesRequest,
  NotesIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiNotes = (
    saveNotesRequest: SaveNotesRequest,
 ) => {
      return orvalRequestor<Notes>(
      {url: `/api/notes`, method: 'post',
      data: saveNotesRequest
    },
      );
    }
  


    export const usePostApiNotes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiNotes>, TError,{data: SaveNotesRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiNotes>, {data: SaveNotesRequest}> = (props) => {
          const {data} = props || {};

          return  postApiNotes(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiNotes>, TError, {data: SaveNotesRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiNotesId = (
    id: string,
 ) => {
      return orvalRequestor<Notes>(
      {url: `/api/notes/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiNotesIdQueryKey = (id: string,) => [`/api/notes/${id}`];

    
export const useGetApiNotesId = <TData = AsyncReturnType<typeof getApiNotesId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiNotesId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiNotesIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiNotesId>> = () => getApiNotesId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiNotesId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiNotesId = (
    id: string,
    saveNotesRequest: SaveNotesRequest,
 ) => {
      return orvalRequestor<Notes>(
      {url: `/api/notes/${id}`, method: 'put',
      data: saveNotesRequest
    },
      );
    }
  


    export const usePutApiNotesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiNotesId>, TError,{id: string;data: SaveNotesRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiNotesId>, {id: string;data: SaveNotesRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiNotesId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiNotesId>, TError, {id: string;data: SaveNotesRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiNotesId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/notes/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiNotesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiNotesId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiNotesId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiNotesId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiNotesId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiNotesFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<NotesIPagedResultList>(
      {url: `/api/notes/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiNotesFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiNotesFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiNotesFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiNotesFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiNotesFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiNotesQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/notes/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiNotesQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiNotesQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiNotesQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiNotesQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiNotesQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    