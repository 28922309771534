/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ShopTabBundle,
  SaveShopTabBundleRequest,
  ShopTabBundleIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiShopTabBundle = (
    saveShopTabBundleRequest: SaveShopTabBundleRequest,
 ) => {
      return orvalRequestor<ShopTabBundle>(
      {url: `/api/shop-tab-bundle`, method: 'post',
      data: saveShopTabBundleRequest
    },
      );
    }
  


    export const usePostApiShopTabBundle = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabBundle>, TError,{data: SaveShopTabBundleRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabBundle>, {data: SaveShopTabBundleRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabBundle(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabBundle>, TError, {data: SaveShopTabBundleRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiShopTabBundleId = (
    id: string,
    saveShopTabBundleRequest: SaveShopTabBundleRequest,
 ) => {
      return orvalRequestor<ShopTabBundle>(
      {url: `/api/shop-tab-bundle/${id}`, method: 'put',
      data: saveShopTabBundleRequest
    },
      );
    }
  


    export const usePutApiShopTabBundleId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiShopTabBundleId>, TError,{id: string;data: SaveShopTabBundleRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiShopTabBundleId>, {id: string;data: SaveShopTabBundleRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiShopTabBundleId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiShopTabBundleId>, TError, {id: string;data: SaveShopTabBundleRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiShopTabBundleId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shop-tab-bundle/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiShopTabBundleId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiShopTabBundleId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiShopTabBundleId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiShopTabBundleId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiShopTabBundleId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiShopTabBundleId = (
    id: string,
 ) => {
      return orvalRequestor<ShopTabBundle>(
      {url: `/api/shop-tab-bundle/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiShopTabBundleIdQueryKey = (id: string,) => [`/api/shop-tab-bundle/${id}`];

    
export const useGetApiShopTabBundleId = <TData = AsyncReturnType<typeof getApiShopTabBundleId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiShopTabBundleId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiShopTabBundleIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiShopTabBundleId>> = () => getApiShopTabBundleId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiShopTabBundleId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiShopTabBundleFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ShopTabBundleIPagedResultList>(
      {url: `/api/shop-tab-bundle/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopTabBundleFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabBundleFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabBundleFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabBundleFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabBundleFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopTabBundleQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/shop-tab-bundle/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopTabBundleQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabBundleQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabBundleQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabBundleQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabBundleQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    