/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useMutation,
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  AdminRequestIPagedResultList,
  EntitySearch
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiAdminRequestFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<AdminRequestIPagedResultList>(
      {url: `/api/admin-request/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiAdminRequestFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiAdminRequestFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiAdminRequestFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiAdminRequestFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiAdminRequestFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    