/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ItemTokenSupportedBlockchainResult
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenId = (
    itemTokenId: string,
 ) => {
      return orvalRequestor<ItemTokenSupportedBlockchainResult[]>(
      {url: `/api/item-token-supported-blockchain/GetByItemTokenId/${itemTokenId}`, method: 'get'
    },
      );
    }
  

export const getGetApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenIdQueryKey = (itemTokenId: string,) => [`/api/item-token-supported-blockchain/GetByItemTokenId/${itemTokenId}`];

    
export const useGetApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenId = <TData = AsyncReturnType<typeof getApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenId>, TError = unknown>(
 itemTokenId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenIdQueryKey(itemTokenId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenId>> = () => getApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenId(itemTokenId, );

  const query = useQuery<AsyncReturnType<typeof getApiItemTokenSupportedBlockchainGetByItemTokenIdItemTokenId>, TError, TData>(queryKey, queryFn, {enabled: !!(itemTokenId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

