/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Permission,
  SavePermissionRequest,
  PermissionIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiPermissionBulk = (
    savePermissionRequest: SavePermissionRequest[],
 ) => {
      return orvalRequestor<Permission[]>(
      {url: `/api/permission/bulk`, method: 'post',
      data: savePermissionRequest
    },
      );
    }
  


    export const usePostApiPermissionBulk = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPermissionBulk>, TError,{data: SavePermissionRequest[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPermissionBulk>, {data: SavePermissionRequest[]}> = (props) => {
          const {data} = props || {};

          return  postApiPermissionBulk(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPermissionBulk>, TError, {data: SavePermissionRequest[]}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiPermissionId = (
    id: string,
 ) => {
      return orvalRequestor<Permission>(
      {url: `/api/permission/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiPermissionIdQueryKey = (id: string,) => [`/api/permission/${id}`];

    
export const useGetApiPermissionId = <TData = AsyncReturnType<typeof getApiPermissionId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPermissionId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPermissionIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPermissionId>> = () => getApiPermissionId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiPermissionId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiPermissionId = (
    id: string,
    savePermissionRequest: SavePermissionRequest,
 ) => {
      return orvalRequestor<Permission>(
      {url: `/api/permission/${id}`, method: 'put',
      data: savePermissionRequest
    },
      );
    }
  


    export const usePutApiPermissionId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiPermissionId>, TError,{id: string;data: SavePermissionRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiPermissionId>, {id: string;data: SavePermissionRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiPermissionId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiPermissionId>, TError, {id: string;data: SavePermissionRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiPermissionId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/permission/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiPermissionId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiPermissionId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiPermissionId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiPermissionId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiPermissionId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPermissionFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<PermissionIPagedResultList>(
      {url: `/api/permission/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiPermissionFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPermissionFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPermissionFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiPermissionFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPermissionFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPermissionQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/permission/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiPermissionQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPermissionQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPermissionQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiPermissionQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPermissionQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPermission = (
    savePermissionRequest: SavePermissionRequest,
 ) => {
      return orvalRequestor<Permission>(
      {url: `/api/permission`, method: 'post',
      data: savePermissionRequest
    },
      );
    }
  


    export const usePostApiPermission = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPermission>, TError,{data: SavePermissionRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPermission>, {data: SavePermissionRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPermission(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPermission>, TError, {data: SavePermissionRequest}, TContext>(mutationFn, mutationOptions)
    }
    