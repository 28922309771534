/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  BundleAggregateViewModel,
  SaveBundleCommand,
  InventoryContainerIPagedResultList,
  GetApiBundlesParams,
  InventoryContainer,
  SaveInventoryContainerRequest,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiBundlesAggregateId = (
    id: string,
 ) => {
      return orvalRequestor<BundleAggregateViewModel>(
      {url: `/api/bundles/aggregate/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiBundlesAggregateIdQueryKey = (id: string,) => [`/api/bundles/aggregate/${id}`];

    
export const useGetApiBundlesAggregateId = <TData = AsyncReturnType<typeof getApiBundlesAggregateId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiBundlesAggregateId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiBundlesAggregateIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiBundlesAggregateId>> = () => getApiBundlesAggregateId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiBundlesAggregateId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiBundlesAggregateId = (
    id: string,
    saveBundleCommand: SaveBundleCommand,
 ) => {
      return orvalRequestor<BundleAggregateViewModel>(
      {url: `/api/bundles/aggregate/${id}`, method: 'put',
      data: saveBundleCommand
    },
      );
    }
  


    export const usePutApiBundlesAggregateId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiBundlesAggregateId>, TError,{id: string;data: SaveBundleCommand}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiBundlesAggregateId>, {id: string;data: SaveBundleCommand}> = (props) => {
          const {id,data} = props || {};

          return  putApiBundlesAggregateId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiBundlesAggregateId>, TError, {id: string;data: SaveBundleCommand}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiBundlesAggregate = (
    saveBundleCommand: SaveBundleCommand,
 ) => {
      return orvalRequestor<BundleAggregateViewModel>(
      {url: `/api/bundles/aggregate`, method: 'post',
      data: saveBundleCommand
    },
      );
    }
  


    export const usePostApiBundlesAggregate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiBundlesAggregate>, TError,{data: SaveBundleCommand}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiBundlesAggregate>, {data: SaveBundleCommand}> = (props) => {
          const {data} = props || {};

          return  postApiBundlesAggregate(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiBundlesAggregate>, TError, {data: SaveBundleCommand}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiBundles = (
    params?: GetApiBundlesParams,
 ) => {
      return orvalRequestor<InventoryContainerIPagedResultList>(
      {url: `/api/bundles`, method: 'get',
        params,
    },
      );
    }
  

export const getGetApiBundlesQueryKey = (params?: GetApiBundlesParams,) => [`/api/bundles`, ...(params ? [params]: [])];

    
export const useGetApiBundles = <TData = AsyncReturnType<typeof getApiBundles>, TError = unknown>(
 params?: GetApiBundlesParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiBundles>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiBundlesQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiBundles>> = () => getApiBundles(params, );

  const query = useQuery<AsyncReturnType<typeof getApiBundles>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiBundles = (
    saveInventoryContainerRequest: SaveInventoryContainerRequest,
 ) => {
      return orvalRequestor<InventoryContainer>(
      {url: `/api/bundles`, method: 'post',
      data: saveInventoryContainerRequest
    },
      );
    }
  


    export const usePostApiBundles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiBundles>, TError,{data: SaveInventoryContainerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiBundles>, {data: SaveInventoryContainerRequest}> = (props) => {
          const {data} = props || {};

          return  postApiBundles(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiBundles>, TError, {data: SaveInventoryContainerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiBundlesId = (
    id: string,
 ) => {
      return orvalRequestor<InventoryContainer>(
      {url: `/api/bundles/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiBundlesIdQueryKey = (id: string,) => [`/api/bundles/${id}`];

    
export const useGetApiBundlesId = <TData = AsyncReturnType<typeof getApiBundlesId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiBundlesId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiBundlesIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiBundlesId>> = () => getApiBundlesId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiBundlesId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiBundlesId = (
    id: string,
    saveInventoryContainerRequest: SaveInventoryContainerRequest,
 ) => {
      return orvalRequestor<InventoryContainer>(
      {url: `/api/bundles/${id}`, method: 'put',
      data: saveInventoryContainerRequest
    },
      );
    }
  


    export const usePutApiBundlesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiBundlesId>, TError,{id: string;data: SaveInventoryContainerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiBundlesId>, {id: string;data: SaveInventoryContainerRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiBundlesId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiBundlesId>, TError, {id: string;data: SaveInventoryContainerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiBundlesId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/bundles/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiBundlesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiBundlesId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiBundlesId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiBundlesId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiBundlesId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiBundlesFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<InventoryContainerIPagedResultList>(
      {url: `/api/bundles/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiBundlesFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiBundlesFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiBundlesFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiBundlesFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiBundlesFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiBundlesQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/bundles/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiBundlesQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiBundlesQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiBundlesQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiBundlesQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiBundlesQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    