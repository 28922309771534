/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Item,
  SaveItemRequest,
  ItemIPagedResultList,
  GetApiItemsParams,
  ObjectIPagedResultList,
  EntitySearch
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiItemsId = (
    id: string,
 ) => {
      return orvalRequestor<Item>(
      {url: `/api/items/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiItemsIdQueryKey = (id: string,) => [`/api/items/${id}`];

    
export const useGetApiItemsId = <TData = AsyncReturnType<typeof getApiItemsId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiItemsId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiItemsIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiItemsId>> = () => getApiItemsId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiItemsId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const deleteApiItemsId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/items/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiItemsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiItemsId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiItemsId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiItemsId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiItemsId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiItemsId = (
    id: string,
    saveItemRequest: SaveItemRequest,
 ) => {
      return orvalRequestor<Item>(
      {url: `/api/items/${id}`, method: 'put',
      data: saveItemRequest
    },
      );
    }
  


    export const usePutApiItemsId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiItemsId>, TError,{id: string;data: SaveItemRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiItemsId>, {id: string;data: SaveItemRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiItemsId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiItemsId>, TError, {id: string;data: SaveItemRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiItems = (
    params?: GetApiItemsParams,
 ) => {
      return orvalRequestor<ItemIPagedResultList>(
      {url: `/api/items`, method: 'get',
        params,
    },
      );
    }
  

export const getGetApiItemsQueryKey = (params?: GetApiItemsParams,) => [`/api/items`, ...(params ? [params]: [])];

    
export const useGetApiItems = <TData = AsyncReturnType<typeof getApiItems>, TError = unknown>(
 params?: GetApiItemsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiItems>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiItemsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiItems>> = () => getApiItems(params, );

  const query = useQuery<AsyncReturnType<typeof getApiItems>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiItems = (
    saveItemRequest: SaveItemRequest,
 ) => {
      return orvalRequestor<Item>(
      {url: `/api/items`, method: 'post',
      data: saveItemRequest
    },
      );
    }
  


    export const usePostApiItems = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItems>, TError,{data: SaveItemRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItems>, {data: SaveItemRequest}> = (props) => {
          const {data} = props || {};

          return  postApiItems(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItems>, TError, {data: SaveItemRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemsBulk = (
    saveItemRequest: SaveItemRequest[],
 ) => {
      return orvalRequestor<Item[]>(
      {url: `/api/items/bulk`, method: 'post',
      data: saveItemRequest
    },
      );
    }
  


    export const usePostApiItemsBulk = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemsBulk>, TError,{data: SaveItemRequest[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemsBulk>, {data: SaveItemRequest[]}> = (props) => {
          const {data} = props || {};

          return  postApiItemsBulk(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemsBulk>, TError, {data: SaveItemRequest[]}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemsQueryForAddContent = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/items/query-for-add-content`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiItemsQueryForAddContent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemsQueryForAddContent>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemsQueryForAddContent>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiItemsQueryForAddContent(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemsQueryForAddContent>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemsFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ItemIPagedResultList>(
      {url: `/api/items/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiItemsFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemsFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemsFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiItemsFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemsFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemsQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/items/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiItemsQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemsQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemsQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiItemsQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemsQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    