/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ShopVirtualCurrency,
  SaveShopVirtualCurrencyRequest,
  ShopVirtualCurrencyIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiShopVirtualCurrency = (
    saveShopVirtualCurrencyRequest: SaveShopVirtualCurrencyRequest,
 ) => {
      return orvalRequestor<ShopVirtualCurrency>(
      {url: `/api/shop-virtual-currency`, method: 'post',
      data: saveShopVirtualCurrencyRequest
    },
      );
    }
  


    export const usePostApiShopVirtualCurrency = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopVirtualCurrency>, TError,{data: SaveShopVirtualCurrencyRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopVirtualCurrency>, {data: SaveShopVirtualCurrencyRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShopVirtualCurrency(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopVirtualCurrency>, TError, {data: SaveShopVirtualCurrencyRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiShopVirtualCurrencyId = (
    id: string,
    saveShopVirtualCurrencyRequest: SaveShopVirtualCurrencyRequest,
 ) => {
      return orvalRequestor<ShopVirtualCurrency>(
      {url: `/api/shop-virtual-currency/${id}`, method: 'put',
      data: saveShopVirtualCurrencyRequest
    },
      );
    }
  


    export const usePutApiShopVirtualCurrencyId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiShopVirtualCurrencyId>, TError,{id: string;data: SaveShopVirtualCurrencyRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiShopVirtualCurrencyId>, {id: string;data: SaveShopVirtualCurrencyRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiShopVirtualCurrencyId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiShopVirtualCurrencyId>, TError, {id: string;data: SaveShopVirtualCurrencyRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiShopVirtualCurrencyId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shop-virtual-currency/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiShopVirtualCurrencyId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiShopVirtualCurrencyId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiShopVirtualCurrencyId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiShopVirtualCurrencyId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiShopVirtualCurrencyId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiShopVirtualCurrencyId = (
    id: string,
 ) => {
      return orvalRequestor<ShopVirtualCurrency>(
      {url: `/api/shop-virtual-currency/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiShopVirtualCurrencyIdQueryKey = (id: string,) => [`/api/shop-virtual-currency/${id}`];

    
export const useGetApiShopVirtualCurrencyId = <TData = AsyncReturnType<typeof getApiShopVirtualCurrencyId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiShopVirtualCurrencyId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiShopVirtualCurrencyIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiShopVirtualCurrencyId>> = () => getApiShopVirtualCurrencyId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiShopVirtualCurrencyId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiShopVirtualCurrencyFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ShopVirtualCurrencyIPagedResultList>(
      {url: `/api/shop-virtual-currency/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopVirtualCurrencyFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopVirtualCurrencyFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopVirtualCurrencyFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopVirtualCurrencyFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopVirtualCurrencyFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopVirtualCurrencyQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/shop-virtual-currency/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopVirtualCurrencyQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopVirtualCurrencyQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopVirtualCurrencyQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopVirtualCurrencyQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopVirtualCurrencyQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    