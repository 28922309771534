/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ItemTokenMetadata,
  SaveItemTokenMetadataRequest,
  ItemTokenMetadataIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiItemTokenMetadata = (
    saveItemTokenMetadataRequest: SaveItemTokenMetadataRequest,
 ) => {
      return orvalRequestor<ItemTokenMetadata>(
      {url: `/api/item-token-metadata`, method: 'post',
      data: saveItemTokenMetadataRequest
    },
      );
    }
  


    export const usePostApiItemTokenMetadata = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemTokenMetadata>, TError,{data: SaveItemTokenMetadataRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemTokenMetadata>, {data: SaveItemTokenMetadataRequest}> = (props) => {
          const {data} = props || {};

          return  postApiItemTokenMetadata(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemTokenMetadata>, TError, {data: SaveItemTokenMetadataRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiItemTokenMetadataId = (
    id: string,
    saveItemTokenMetadataRequest: SaveItemTokenMetadataRequest,
 ) => {
      return orvalRequestor<ItemTokenMetadata>(
      {url: `/api/item-token-metadata/${id}`, method: 'put',
      data: saveItemTokenMetadataRequest
    },
      );
    }
  


    export const usePutApiItemTokenMetadataId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiItemTokenMetadataId>, TError,{id: string;data: SaveItemTokenMetadataRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiItemTokenMetadataId>, {id: string;data: SaveItemTokenMetadataRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiItemTokenMetadataId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiItemTokenMetadataId>, TError, {id: string;data: SaveItemTokenMetadataRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiItemTokenMetadataId = (
    id: string,
 ) => {
      return orvalRequestor<ItemTokenMetadata>(
      {url: `/api/item-token-metadata/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiItemTokenMetadataIdQueryKey = (id: string,) => [`/api/item-token-metadata/${id}`];

    
export const useGetApiItemTokenMetadataId = <TData = AsyncReturnType<typeof getApiItemTokenMetadataId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiItemTokenMetadataId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiItemTokenMetadataIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiItemTokenMetadataId>> = () => getApiItemTokenMetadataId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiItemTokenMetadataId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const deleteApiItemTokenMetadataId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/item-token-metadata/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiItemTokenMetadataId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiItemTokenMetadataId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiItemTokenMetadataId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiItemTokenMetadataId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiItemTokenMetadataId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemTokenMetadataFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ItemTokenMetadataIPagedResultList>(
      {url: `/api/item-token-metadata/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiItemTokenMetadataFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemTokenMetadataFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemTokenMetadataFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiItemTokenMetadataFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemTokenMetadataFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemTokenMetadataQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/item-token-metadata/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiItemTokenMetadataQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemTokenMetadataQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemTokenMetadataQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiItemTokenMetadataQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemTokenMetadataQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    