/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  VirtualCurrency,
  SaveVirtualCurrencyRequest,
  VirtualCurrencyIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiVirtualCurrencyByGameGameId = (
    gameId: string,
 ) => {
      return orvalRequestor<VirtualCurrency[]>(
      {url: `/api/virtual-currency/by-game/${gameId}`, method: 'get'
    },
      );
    }
  

export const getGetApiVirtualCurrencyByGameGameIdQueryKey = (gameId: string,) => [`/api/virtual-currency/by-game/${gameId}`];

    
export const useGetApiVirtualCurrencyByGameGameId = <TData = AsyncReturnType<typeof getApiVirtualCurrencyByGameGameId>, TError = unknown>(
 gameId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiVirtualCurrencyByGameGameId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiVirtualCurrencyByGameGameIdQueryKey(gameId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiVirtualCurrencyByGameGameId>> = () => getApiVirtualCurrencyByGameGameId(gameId, );

  const query = useQuery<AsyncReturnType<typeof getApiVirtualCurrencyByGameGameId>, TError, TData>(queryKey, queryFn, {enabled: !!(gameId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const getApiVirtualCurrencyByEnvironment = (
    
 ) => {
      return orvalRequestor<VirtualCurrency[]>(
      {url: `/api/virtual-currency/by-environment`, method: 'get'
    },
      );
    }
  

export const getGetApiVirtualCurrencyByEnvironmentQueryKey = () => [`/api/virtual-currency/by-environment`];

    
export const useGetApiVirtualCurrencyByEnvironment = <TData = AsyncReturnType<typeof getApiVirtualCurrencyByEnvironment>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiVirtualCurrencyByEnvironment>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiVirtualCurrencyByEnvironmentQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiVirtualCurrencyByEnvironment>> = () => getApiVirtualCurrencyByEnvironment();

  const query = useQuery<AsyncReturnType<typeof getApiVirtualCurrencyByEnvironment>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getApiVirtualCurrencyId = (
    id: string,
 ) => {
      return orvalRequestor<VirtualCurrency>(
      {url: `/api/virtual-currency/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiVirtualCurrencyIdQueryKey = (id: string,) => [`/api/virtual-currency/${id}`];

    
export const useGetApiVirtualCurrencyId = <TData = AsyncReturnType<typeof getApiVirtualCurrencyId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiVirtualCurrencyId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiVirtualCurrencyIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiVirtualCurrencyId>> = () => getApiVirtualCurrencyId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiVirtualCurrencyId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiVirtualCurrencyId = (
    id: string,
    saveVirtualCurrencyRequest: SaveVirtualCurrencyRequest,
 ) => {
      return orvalRequestor<VirtualCurrency>(
      {url: `/api/virtual-currency/${id}`, method: 'put',
      data: saveVirtualCurrencyRequest
    },
      );
    }
  


    export const usePutApiVirtualCurrencyId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiVirtualCurrencyId>, TError,{id: string;data: SaveVirtualCurrencyRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiVirtualCurrencyId>, {id: string;data: SaveVirtualCurrencyRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiVirtualCurrencyId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiVirtualCurrencyId>, TError, {id: string;data: SaveVirtualCurrencyRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiVirtualCurrencyId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/virtual-currency/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiVirtualCurrencyId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiVirtualCurrencyId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiVirtualCurrencyId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiVirtualCurrencyId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiVirtualCurrencyId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiVirtualCurrencyFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<VirtualCurrencyIPagedResultList>(
      {url: `/api/virtual-currency/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiVirtualCurrencyFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiVirtualCurrencyFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiVirtualCurrencyFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiVirtualCurrencyFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiVirtualCurrencyFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiVirtualCurrencyQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/virtual-currency/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiVirtualCurrencyQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiVirtualCurrencyQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiVirtualCurrencyQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiVirtualCurrencyQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiVirtualCurrencyQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiVirtualCurrency = (
    saveVirtualCurrencyRequest: SaveVirtualCurrencyRequest,
 ) => {
      return orvalRequestor<VirtualCurrency>(
      {url: `/api/virtual-currency`, method: 'post',
      data: saveVirtualCurrencyRequest
    },
      );
    }
  


    export const usePostApiVirtualCurrency = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiVirtualCurrency>, TError,{data: SaveVirtualCurrencyRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiVirtualCurrency>, {data: SaveVirtualCurrencyRequest}> = (props) => {
          const {data} = props || {};

          return  postApiVirtualCurrency(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiVirtualCurrency>, TError, {data: SaveVirtualCurrencyRequest}, TContext>(mutationFn, mutationOptions)
    }
    