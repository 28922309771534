/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CustomData,
  SaveCustomDataRequest,
  CustomDataIPagedResultList,
  EntitySearch
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiBundlesCustomData = (
    saveCustomDataRequest: SaveCustomDataRequest,
 ) => {
      return orvalRequestor<CustomData>(
      {url: `/api/bundles-custom-data`, method: 'post',
      data: saveCustomDataRequest
    },
      );
    }
  


    export const usePostApiBundlesCustomData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiBundlesCustomData>, TError,{data: SaveCustomDataRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiBundlesCustomData>, {data: SaveCustomDataRequest}> = (props) => {
          const {data} = props || {};

          return  postApiBundlesCustomData(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiBundlesCustomData>, TError, {data: SaveCustomDataRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiBundlesCustomDataId = (
    id: string,
    saveCustomDataRequest: SaveCustomDataRequest,
 ) => {
      return orvalRequestor<CustomData>(
      {url: `/api/bundles-custom-data/${id}`, method: 'put',
      data: saveCustomDataRequest
    },
      );
    }
  


    export const usePutApiBundlesCustomDataId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiBundlesCustomDataId>, TError,{id: string;data: SaveCustomDataRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiBundlesCustomDataId>, {id: string;data: SaveCustomDataRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiBundlesCustomDataId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiBundlesCustomDataId>, TError, {id: string;data: SaveCustomDataRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiBundlesCustomDataId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/bundles-custom-data/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiBundlesCustomDataId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiBundlesCustomDataId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiBundlesCustomDataId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiBundlesCustomDataId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiBundlesCustomDataId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiBundlesCustomDataId = (
    id: string,
 ) => {
      return orvalRequestor<CustomData>(
      {url: `/api/bundles-custom-data/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiBundlesCustomDataIdQueryKey = (id: string,) => [`/api/bundles-custom-data/${id}`];

    
export const useGetApiBundlesCustomDataId = <TData = AsyncReturnType<typeof getApiBundlesCustomDataId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiBundlesCustomDataId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiBundlesCustomDataIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiBundlesCustomDataId>> = () => getApiBundlesCustomDataId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiBundlesCustomDataId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiBundlesCustomDataFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<CustomDataIPagedResultList>(
      {url: `/api/bundles-custom-data/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiBundlesCustomDataFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiBundlesCustomDataFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiBundlesCustomDataFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiBundlesCustomDataFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiBundlesCustomDataFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    