/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  SupportedBlockchainResult
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiSupportedBlockchain = (
    
 ) => {
      return orvalRequestor<SupportedBlockchainResult[]>(
      {url: `/api/supported-blockchain`, method: 'get'
    },
      );
    }
  

export const getGetApiSupportedBlockchainQueryKey = () => [`/api/supported-blockchain`];

    
export const useGetApiSupportedBlockchain = <TData = AsyncReturnType<typeof getApiSupportedBlockchain>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiSupportedBlockchain>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiSupportedBlockchainQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiSupportedBlockchain>> = () => getApiSupportedBlockchain();

  const query = useQuery<AsyncReturnType<typeof getApiSupportedBlockchain>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

