/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  PlayerContainer
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiPlayerContainersId = (
    id: string,
 ) => {
      return orvalRequestor<PlayerContainer>(
      {url: `/api/player-containers/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiPlayerContainersIdQueryKey = (id: string,) => [`/api/player-containers/${id}`];

    
export const useGetApiPlayerContainersId = <TData = AsyncReturnType<typeof getApiPlayerContainersId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPlayerContainersId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPlayerContainersIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPlayerContainersId>> = () => getApiPlayerContainersId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiPlayerContainersId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

