/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ContainerAggregateViewModel,
  SaveContainerCommand,
  InventoryContainerIPagedResultList,
  GetApiContainersParams,
  InventoryContainer,
  SaveInventoryContainerRequest,
  GrantInventoryResult,
  PostApiContainersOpenParams,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiContainersAggregateId = (
    id: string,
 ) => {
      return orvalRequestor<ContainerAggregateViewModel>(
      {url: `/api/containers/aggregate/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiContainersAggregateIdQueryKey = (id: string,) => [`/api/containers/aggregate/${id}`];

    
export const useGetApiContainersAggregateId = <TData = AsyncReturnType<typeof getApiContainersAggregateId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiContainersAggregateId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiContainersAggregateIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiContainersAggregateId>> = () => getApiContainersAggregateId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiContainersAggregateId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiContainersAggregateId = (
    id: string,
    saveContainerCommand: SaveContainerCommand,
 ) => {
      return orvalRequestor<ContainerAggregateViewModel>(
      {url: `/api/containers/aggregate/${id}`, method: 'put',
      data: saveContainerCommand
    },
      );
    }
  


    export const usePutApiContainersAggregateId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiContainersAggregateId>, TError,{id: string;data: SaveContainerCommand}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiContainersAggregateId>, {id: string;data: SaveContainerCommand}> = (props) => {
          const {id,data} = props || {};

          return  putApiContainersAggregateId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiContainersAggregateId>, TError, {id: string;data: SaveContainerCommand}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiContainersAggregate = (
    saveContainerCommand: SaveContainerCommand,
 ) => {
      return orvalRequestor<ContainerAggregateViewModel>(
      {url: `/api/containers/aggregate`, method: 'post',
      data: saveContainerCommand
    },
      );
    }
  


    export const usePostApiContainersAggregate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiContainersAggregate>, TError,{data: SaveContainerCommand}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiContainersAggregate>, {data: SaveContainerCommand}> = (props) => {
          const {data} = props || {};

          return  postApiContainersAggregate(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiContainersAggregate>, TError, {data: SaveContainerCommand}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiContainers = (
    params?: GetApiContainersParams,
 ) => {
      return orvalRequestor<InventoryContainerIPagedResultList>(
      {url: `/api/containers`, method: 'get',
        params,
    },
      );
    }
  

export const getGetApiContainersQueryKey = (params?: GetApiContainersParams,) => [`/api/containers`, ...(params ? [params]: [])];

    
export const useGetApiContainers = <TData = AsyncReturnType<typeof getApiContainers>, TError = unknown>(
 params?: GetApiContainersParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiContainers>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiContainersQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiContainers>> = () => getApiContainers(params, );

  const query = useQuery<AsyncReturnType<typeof getApiContainers>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiContainers = (
    saveInventoryContainerRequest: SaveInventoryContainerRequest,
 ) => {
      return orvalRequestor<InventoryContainer>(
      {url: `/api/containers`, method: 'post',
      data: saveInventoryContainerRequest
    },
      );
    }
  


    export const usePostApiContainers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiContainers>, TError,{data: SaveInventoryContainerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiContainers>, {data: SaveInventoryContainerRequest}> = (props) => {
          const {data} = props || {};

          return  postApiContainers(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiContainers>, TError, {data: SaveInventoryContainerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiContainersOpen = (
    params?: PostApiContainersOpenParams,
 ) => {
      return orvalRequestor<GrantInventoryResult>(
      {url: `/api/containers/open`, method: 'post',
        params,
    },
      );
    }
  


    export const usePostApiContainersOpen = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiContainersOpen>, TError,{params?: PostApiContainersOpenParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiContainersOpen>, {params?: PostApiContainersOpenParams}> = (props) => {
          const {params} = props || {};

          return  postApiContainersOpen(params,)
        }

      return useMutation<AsyncReturnType<typeof postApiContainersOpen>, TError, {params?: PostApiContainersOpenParams}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiContainersId = (
    id: string,
 ) => {
      return orvalRequestor<InventoryContainer>(
      {url: `/api/containers/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiContainersIdQueryKey = (id: string,) => [`/api/containers/${id}`];

    
export const useGetApiContainersId = <TData = AsyncReturnType<typeof getApiContainersId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiContainersId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiContainersIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiContainersId>> = () => getApiContainersId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiContainersId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiContainersId = (
    id: string,
    saveInventoryContainerRequest: SaveInventoryContainerRequest,
 ) => {
      return orvalRequestor<InventoryContainer>(
      {url: `/api/containers/${id}`, method: 'put',
      data: saveInventoryContainerRequest
    },
      );
    }
  


    export const usePutApiContainersId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiContainersId>, TError,{id: string;data: SaveInventoryContainerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiContainersId>, {id: string;data: SaveInventoryContainerRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiContainersId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiContainersId>, TError, {id: string;data: SaveInventoryContainerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiContainersId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/containers/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiContainersId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiContainersId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiContainersId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiContainersId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiContainersId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiContainersFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<InventoryContainerIPagedResultList>(
      {url: `/api/containers/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiContainersFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiContainersFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiContainersFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiContainersFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiContainersFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiContainersQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/containers/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiContainersQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiContainersQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiContainersQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiContainersQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiContainersQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    