/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ItemClassModel,
  SaveItemClassRequest,
  ItemClassModelIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiItemClasses = (
    
 ) => {
      return orvalRequestor<ItemClassModel[]>(
      {url: `/api/item-classes`, method: 'get'
    },
      );
    }
  

export const getGetApiItemClassesQueryKey = () => [`/api/item-classes`];

    
export const useGetApiItemClasses = <TData = AsyncReturnType<typeof getApiItemClasses>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiItemClasses>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiItemClassesQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiItemClasses>> = () => getApiItemClasses();

  const query = useQuery<AsyncReturnType<typeof getApiItemClasses>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiItemClasses = (
    saveItemClassRequest: SaveItemClassRequest,
 ) => {
      return orvalRequestor<ItemClassModel>(
      {url: `/api/item-classes`, method: 'post',
      data: saveItemClassRequest
    },
      );
    }
  


    export const usePostApiItemClasses = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemClasses>, TError,{data: SaveItemClassRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemClasses>, {data: SaveItemClassRequest}> = (props) => {
          const {data} = props || {};

          return  postApiItemClasses(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemClasses>, TError, {data: SaveItemClassRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiItemClassesId = (
    id: string,
    saveItemClassRequest: SaveItemClassRequest,
 ) => {
      return orvalRequestor<ItemClassModel>(
      {url: `/api/item-classes/${id}`, method: 'put',
      data: saveItemClassRequest
    },
      );
    }
  


    export const usePutApiItemClassesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiItemClassesId>, TError,{id: string;data: SaveItemClassRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiItemClassesId>, {id: string;data: SaveItemClassRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiItemClassesId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiItemClassesId>, TError, {id: string;data: SaveItemClassRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiItemClassesId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/item-classes/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiItemClassesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiItemClassesId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiItemClassesId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiItemClassesId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiItemClassesId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiItemClassesId = (
    id: string,
 ) => {
      return orvalRequestor<ItemClassModel>(
      {url: `/api/item-classes/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiItemClassesIdQueryKey = (id: string,) => [`/api/item-classes/${id}`];

    
export const useGetApiItemClassesId = <TData = AsyncReturnType<typeof getApiItemClassesId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiItemClassesId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiItemClassesIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiItemClassesId>> = () => getApiItemClassesId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiItemClassesId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiItemClassesFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ItemClassModelIPagedResultList>(
      {url: `/api/item-classes/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiItemClassesFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemClassesFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemClassesFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiItemClassesFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemClassesFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiItemClassesQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/item-classes/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiItemClassesQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiItemClassesQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiItemClassesQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiItemClassesQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiItemClassesQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    