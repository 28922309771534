/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Authorization,
  AdminUser,
  SaveAdminUserRequest,
  AdminUserIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiAdminUserAuthorize = (
    
 ) => {
      return orvalRequestor<Authorization>(
      {url: `/api/admin-user/authorize`, method: 'get'
    },
      );
    }
  

export const getGetApiAdminUserAuthorizeQueryKey = () => [`/api/admin-user/authorize`];

    
export const useGetApiAdminUserAuthorize = <TData = AsyncReturnType<typeof getApiAdminUserAuthorize>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiAdminUserAuthorize>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiAdminUserAuthorizeQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiAdminUserAuthorize>> = () => getApiAdminUserAuthorize();

  const query = useQuery<AsyncReturnType<typeof getApiAdminUserAuthorize>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiAdminUser = (
    saveAdminUserRequest: SaveAdminUserRequest,
 ) => {
      return orvalRequestor<AdminUser>(
      {url: `/api/admin-user`, method: 'post',
      data: saveAdminUserRequest
    },
      );
    }
  


    export const usePostApiAdminUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiAdminUser>, TError,{data: SaveAdminUserRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiAdminUser>, {data: SaveAdminUserRequest}> = (props) => {
          const {data} = props || {};

          return  postApiAdminUser(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiAdminUser>, TError, {data: SaveAdminUserRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiAdminUserId = (
    id: string,
 ) => {
      return orvalRequestor<AdminUser>(
      {url: `/api/admin-user/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiAdminUserIdQueryKey = (id: string,) => [`/api/admin-user/${id}`];

    
export const useGetApiAdminUserId = <TData = AsyncReturnType<typeof getApiAdminUserId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiAdminUserId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiAdminUserIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiAdminUserId>> = () => getApiAdminUserId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiAdminUserId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiAdminUserId = (
    id: string,
    saveAdminUserRequest: SaveAdminUserRequest,
 ) => {
      return orvalRequestor<AdminUser>(
      {url: `/api/admin-user/${id}`, method: 'put',
      data: saveAdminUserRequest
    },
      );
    }
  


    export const usePutApiAdminUserId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiAdminUserId>, TError,{id: string;data: SaveAdminUserRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiAdminUserId>, {id: string;data: SaveAdminUserRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiAdminUserId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiAdminUserId>, TError, {id: string;data: SaveAdminUserRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiAdminUserId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/admin-user/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiAdminUserId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiAdminUserId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiAdminUserId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiAdminUserId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiAdminUserId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiAdminUserFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<AdminUserIPagedResultList>(
      {url: `/api/admin-user/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiAdminUserFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiAdminUserFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiAdminUserFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiAdminUserFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiAdminUserFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiAdminUserQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/admin-user/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiAdminUserQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiAdminUserQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiAdminUserQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiAdminUserQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiAdminUserQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    