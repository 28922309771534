/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  PlayerItem,
  PlayerInventoryResult,
  PlayerItemIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList,
  AdminUpdatePlayerItemLockRequest,
  BaseBackofficeResult,
  SyncOnChainItemsRequest,
  GetApiPlayerItemsPlayerItemIdCustomData200,
  GrantInventoryResult,
  GrantItemsToPlayerRequest,
  SyncPlayerItemOwnershipRequest
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiPlayerItemsId = (
    id: string,
 ) => {
      return orvalRequestor<PlayerItem>(
      {url: `/api/player-items/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiPlayerItemsIdQueryKey = (id: string,) => [`/api/player-items/${id}`];

    
export const useGetApiPlayerItemsId = <TData = AsyncReturnType<typeof getApiPlayerItemsId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPlayerItemsId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPlayerItemsIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPlayerItemsId>> = () => getApiPlayerItemsId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiPlayerItemsId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const getApiPlayerItemsInventoryId = (
    id: string,
 ) => {
      return orvalRequestor<PlayerInventoryResult>(
      {url: `/api/player-items/inventory/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiPlayerItemsInventoryIdQueryKey = (id: string,) => [`/api/player-items/inventory/${id}`];

    
export const useGetApiPlayerItemsInventoryId = <TData = AsyncReturnType<typeof getApiPlayerItemsInventoryId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPlayerItemsInventoryId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPlayerItemsInventoryIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPlayerItemsInventoryId>> = () => getApiPlayerItemsInventoryId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiPlayerItemsInventoryId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiPlayerItemsFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<PlayerItemIPagedResultList>(
      {url: `/api/player-items/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiPlayerItemsFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayerItemsFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayerItemsFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiPlayerItemsFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayerItemsFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayerItemsQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/player-items/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiPlayerItemsQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayerItemsQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayerItemsQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiPlayerItemsQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayerItemsQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiPlayerItemsPlayerItemId = (
    playerItemId: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/player-items/${playerItemId}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiPlayerItemsPlayerItemId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiPlayerItemsPlayerItemId>, TError,{playerItemId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiPlayerItemsPlayerItemId>, {playerItemId: string}> = (props) => {
          const {playerItemId} = props || {};

          return  deleteApiPlayerItemsPlayerItemId(playerItemId,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiPlayerItemsPlayerItemId>, TError, {playerItemId: string}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiPlayerItemsPlayerItemIdLock = (
    playerItemId: string,
    adminUpdatePlayerItemLockRequest: AdminUpdatePlayerItemLockRequest,
 ) => {
      return orvalRequestor<PlayerItem>(
      {url: `/api/player-items/${playerItemId}/lock`, method: 'put',
      data: adminUpdatePlayerItemLockRequest
    },
      );
    }
  


    export const usePutApiPlayerItemsPlayerItemIdLock = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiPlayerItemsPlayerItemIdLock>, TError,{playerItemId: string;data: AdminUpdatePlayerItemLockRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiPlayerItemsPlayerItemIdLock>, {playerItemId: string;data: AdminUpdatePlayerItemLockRequest}> = (props) => {
          const {playerItemId,data} = props || {};

          return  putApiPlayerItemsPlayerItemIdLock(playerItemId,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiPlayerItemsPlayerItemIdLock>, TError, {playerItemId: string;data: AdminUpdatePlayerItemLockRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayerItemsSync = (
    syncOnChainItemsRequest: SyncOnChainItemsRequest,
 ) => {
      return orvalRequestor<BaseBackofficeResult>(
      {url: `/api/player-items/sync`, method: 'post',
      data: syncOnChainItemsRequest
    },
      );
    }
  


    export const usePostApiPlayerItemsSync = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayerItemsSync>, TError,{data: SyncOnChainItemsRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayerItemsSync>, {data: SyncOnChainItemsRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPlayerItemsSync(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayerItemsSync>, TError, {data: SyncOnChainItemsRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiPlayerItemsPlayerItemIdCustomData = (
    playerItemId: string,
 ) => {
      return orvalRequestor<GetApiPlayerItemsPlayerItemIdCustomData200>(
      {url: `/api/player-items/${playerItemId}/customData`, method: 'get'
    },
      );
    }
  

export const getGetApiPlayerItemsPlayerItemIdCustomDataQueryKey = (playerItemId: string,) => [`/api/player-items/${playerItemId}/customData`];

    
export const useGetApiPlayerItemsPlayerItemIdCustomData = <TData = AsyncReturnType<typeof getApiPlayerItemsPlayerItemIdCustomData>, TError = unknown>(
 playerItemId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPlayerItemsPlayerItemIdCustomData>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPlayerItemsPlayerItemIdCustomDataQueryKey(playerItemId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPlayerItemsPlayerItemIdCustomData>> = () => getApiPlayerItemsPlayerItemIdCustomData(playerItemId, );

  const query = useQuery<AsyncReturnType<typeof getApiPlayerItemsPlayerItemIdCustomData>, TError, TData>(queryKey, queryFn, {enabled: !!(playerItemId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiPlayerItems = (
    grantItemsToPlayerRequest: GrantItemsToPlayerRequest,
 ) => {
      return orvalRequestor<GrantInventoryResult>(
      {url: `/api/player-items`, method: 'post',
      data: grantItemsToPlayerRequest
    },
      );
    }
  


    export const usePostApiPlayerItems = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayerItems>, TError,{data: GrantItemsToPlayerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayerItems>, {data: GrantItemsToPlayerRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPlayerItems(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayerItems>, TError, {data: GrantItemsToPlayerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayerItemsSyncWithBlockchain = (
    syncPlayerItemOwnershipRequest: SyncPlayerItemOwnershipRequest,
 ) => {
      return orvalRequestor<BaseBackofficeResult>(
      {url: `/api/player-items/sync-with-blockchain`, method: 'post',
      data: syncPlayerItemOwnershipRequest
    },
      );
    }
  


    export const usePostApiPlayerItemsSyncWithBlockchain = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayerItemsSyncWithBlockchain>, TError,{data: SyncPlayerItemOwnershipRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayerItemsSyncWithBlockchain>, {data: SyncPlayerItemOwnershipRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPlayerItemsSyncWithBlockchain(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayerItemsSyncWithBlockchain>, TError, {data: SyncPlayerItemOwnershipRequest}, TContext>(mutationFn, mutationOptions)
    }
    