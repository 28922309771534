/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AdminUserRole,
  SaveAdminUserRoleRequest,
  AdminUserRoleIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiAdminUserRoleId = (
    id: string,
 ) => {
      return orvalRequestor<AdminUserRole>(
      {url: `/api/admin-user-role/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiAdminUserRoleIdQueryKey = (id: string,) => [`/api/admin-user-role/${id}`];

    
export const useGetApiAdminUserRoleId = <TData = AsyncReturnType<typeof getApiAdminUserRoleId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiAdminUserRoleId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiAdminUserRoleIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiAdminUserRoleId>> = () => getApiAdminUserRoleId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiAdminUserRoleId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiAdminUserRoleId = (
    id: string,
    saveAdminUserRoleRequest: SaveAdminUserRoleRequest,
 ) => {
      return orvalRequestor<AdminUserRole>(
      {url: `/api/admin-user-role/${id}`, method: 'put',
      data: saveAdminUserRoleRequest
    },
      );
    }
  


    export const usePutApiAdminUserRoleId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiAdminUserRoleId>, TError,{id: string;data: SaveAdminUserRoleRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiAdminUserRoleId>, {id: string;data: SaveAdminUserRoleRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiAdminUserRoleId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiAdminUserRoleId>, TError, {id: string;data: SaveAdminUserRoleRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiAdminUserRoleId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/admin-user-role/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiAdminUserRoleId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiAdminUserRoleId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiAdminUserRoleId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiAdminUserRoleId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiAdminUserRoleId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiAdminUserRoleFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<AdminUserRoleIPagedResultList>(
      {url: `/api/admin-user-role/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiAdminUserRoleFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiAdminUserRoleFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiAdminUserRoleFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiAdminUserRoleFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiAdminUserRoleFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiAdminUserRoleQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/admin-user-role/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiAdminUserRoleQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiAdminUserRoleQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiAdminUserRoleQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiAdminUserRoleQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiAdminUserRoleQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiAdminUserRole = (
    saveAdminUserRoleRequest: SaveAdminUserRoleRequest,
 ) => {
      return orvalRequestor<AdminUserRole>(
      {url: `/api/admin-user-role`, method: 'post',
      data: saveAdminUserRoleRequest
    },
      );
    }
  


    export const usePostApiAdminUserRole = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiAdminUserRole>, TError,{data: SaveAdminUserRoleRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiAdminUserRole>, {data: SaveAdminUserRoleRequest}> = (props) => {
          const {data} = props || {};

          return  postApiAdminUserRole(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiAdminUserRole>, TError, {data: SaveAdminUserRoleRequest}, TContext>(mutationFn, mutationOptions)
    }
    