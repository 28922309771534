/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  BaseBackofficeResult,
  PostApiPacksUploadBody,
  BasePack,
  PackDetail,
  PackContents
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiPacksUpload = (
    postApiPacksUploadBody: PostApiPacksUploadBody,
 ) => {const formData = new FormData();
if(postApiPacksUploadBody.csvFile !== undefined) {
 formData.append('csvFile', postApiPacksUploadBody.csvFile)
 }

      return orvalRequestor<BaseBackofficeResult>(
      {url: `/api/packs/upload`, method: 'post',
       data: formData
    },
      );
    }
  


    export const usePostApiPacksUpload = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPacksUpload>, TError,{data: PostApiPacksUploadBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPacksUpload>, {data: PostApiPacksUploadBody}> = (props) => {
          const {data} = props || {};

          return  postApiPacksUpload(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPacksUpload>, TError, {data: PostApiPacksUploadBody}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiPacksByEnvironment = (
    
 ) => {
      return orvalRequestor<BasePack[]>(
      {url: `/api/packs/by-environment`, method: 'get'
    },
      );
    }
  

export const getGetApiPacksByEnvironmentQueryKey = () => [`/api/packs/by-environment`];

    
export const useGetApiPacksByEnvironment = <TData = AsyncReturnType<typeof getApiPacksByEnvironment>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPacksByEnvironment>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPacksByEnvironmentQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPacksByEnvironment>> = () => getApiPacksByEnvironment();

  const query = useQuery<AsyncReturnType<typeof getApiPacksByEnvironment>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getApiPacksByNameName = (
    name: string,
 ) => {
      return orvalRequestor<PackDetail[]>(
      {url: `/api/packs/by-name/${name}`, method: 'get'
    },
      );
    }
  

export const getGetApiPacksByNameNameQueryKey = (name: string,) => [`/api/packs/by-name/${name}`];

    
export const useGetApiPacksByNameName = <TData = AsyncReturnType<typeof getApiPacksByNameName>, TError = unknown>(
 name: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPacksByNameName>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPacksByNameNameQueryKey(name);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPacksByNameName>> = () => getApiPacksByNameName(name, );

  const query = useQuery<AsyncReturnType<typeof getApiPacksByNameName>, TError, TData>(queryKey, queryFn, {enabled: !!(name), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const getApiPacksByNameNameNumberNumber = (
    name: string,
    number: number,
 ) => {
      return orvalRequestor<PackContents>(
      {url: `/api/packs/by-name/${name}/number/${number}`, method: 'get'
    },
      );
    }
  

export const getGetApiPacksByNameNameNumberNumberQueryKey = (name: string,
    number: number,) => [`/api/packs/by-name/${name}/number/${number}`];

    
export const useGetApiPacksByNameNameNumberNumber = <TData = AsyncReturnType<typeof getApiPacksByNameNameNumberNumber>, TError = unknown>(
 name: string,
    number: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPacksByNameNameNumberNumber>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPacksByNameNameNumberNumberQueryKey(name,number);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPacksByNameNameNumberNumber>> = () => getApiPacksByNameNameNumberNumber(name,number, );

  const query = useQuery<AsyncReturnType<typeof getApiPacksByNameNameNumberNumber>, TError, TData>(queryKey, queryFn, {enabled: !!(name && number), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const deleteApiPacksName = (
    name: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/packs/${name}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiPacksName = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiPacksName>, TError,{name: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiPacksName>, {name: string}> = (props) => {
          const {name} = props || {};

          return  deleteApiPacksName(name,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiPacksName>, TError, {name: string}, TContext>(mutationFn, mutationOptions)
    }
    