/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */

export type CustomDataPermission = 'None' | 'Read' | 'Write';


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomDataPermission = {
  None: 'None' as CustomDataPermission,
  Read: 'Read' as CustomDataPermission,
  Write: 'Write' as CustomDataPermission,
};
