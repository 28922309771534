/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ShopBundle,
  SaveShopBundleRequest,
  ShopBundleIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiShopBundle = (
    saveShopBundleRequest: SaveShopBundleRequest,
 ) => {
      return orvalRequestor<ShopBundle>(
      {url: `/api/shop-bundle`, method: 'post',
      data: saveShopBundleRequest
    },
      );
    }
  


    export const usePostApiShopBundle = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopBundle>, TError,{data: SaveShopBundleRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopBundle>, {data: SaveShopBundleRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShopBundle(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopBundle>, TError, {data: SaveShopBundleRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiShopBundleId = (
    id: string,
 ) => {
      return orvalRequestor<ShopBundle>(
      {url: `/api/shop-bundle/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiShopBundleIdQueryKey = (id: string,) => [`/api/shop-bundle/${id}`];

    
export const useGetApiShopBundleId = <TData = AsyncReturnType<typeof getApiShopBundleId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiShopBundleId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiShopBundleIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiShopBundleId>> = () => getApiShopBundleId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiShopBundleId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiShopBundleId = (
    id: string,
    saveShopBundleRequest: SaveShopBundleRequest,
 ) => {
      return orvalRequestor<ShopBundle>(
      {url: `/api/shop-bundle/${id}`, method: 'put',
      data: saveShopBundleRequest
    },
      );
    }
  


    export const usePutApiShopBundleId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiShopBundleId>, TError,{id: string;data: SaveShopBundleRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiShopBundleId>, {id: string;data: SaveShopBundleRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiShopBundleId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiShopBundleId>, TError, {id: string;data: SaveShopBundleRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiShopBundleId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shop-bundle/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiShopBundleId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiShopBundleId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiShopBundleId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiShopBundleId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiShopBundleId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopBundleFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ShopBundleIPagedResultList>(
      {url: `/api/shop-bundle/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopBundleFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopBundleFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopBundleFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopBundleFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopBundleFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopBundleQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/shop-bundle/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopBundleQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopBundleQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopBundleQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopBundleQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopBundleQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    