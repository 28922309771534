/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CustomDataSchema,
  SaveCustomDataSchemaRequest,
  CustomDataSchemaIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiCustomDataSchemaId = (
    id: string,
 ) => {
      return orvalRequestor<CustomDataSchema>(
      {url: `/api/custom-data-schema/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiCustomDataSchemaIdQueryKey = (id: string,) => [`/api/custom-data-schema/${id}`];

    
export const useGetApiCustomDataSchemaId = <TData = AsyncReturnType<typeof getApiCustomDataSchemaId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiCustomDataSchemaId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiCustomDataSchemaIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiCustomDataSchemaId>> = () => getApiCustomDataSchemaId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiCustomDataSchemaId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiCustomDataSchemaId = (
    id: string,
    saveCustomDataSchemaRequest: SaveCustomDataSchemaRequest,
 ) => {
      return orvalRequestor<CustomDataSchema>(
      {url: `/api/custom-data-schema/${id}`, method: 'put',
      data: saveCustomDataSchemaRequest
    },
      );
    }
  


    export const usePutApiCustomDataSchemaId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiCustomDataSchemaId>, TError,{id: string;data: SaveCustomDataSchemaRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiCustomDataSchemaId>, {id: string;data: SaveCustomDataSchemaRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiCustomDataSchemaId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiCustomDataSchemaId>, TError, {id: string;data: SaveCustomDataSchemaRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiCustomDataSchemaId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/custom-data-schema/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiCustomDataSchemaId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiCustomDataSchemaId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiCustomDataSchemaId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiCustomDataSchemaId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiCustomDataSchemaId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiCustomDataSchemaFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<CustomDataSchemaIPagedResultList>(
      {url: `/api/custom-data-schema/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiCustomDataSchemaFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiCustomDataSchemaFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiCustomDataSchemaFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiCustomDataSchemaFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiCustomDataSchemaFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiCustomDataSchemaQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/custom-data-schema/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiCustomDataSchemaQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiCustomDataSchemaQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiCustomDataSchemaQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiCustomDataSchemaQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiCustomDataSchemaQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiCustomDataSchema = (
    saveCustomDataSchemaRequest: SaveCustomDataSchemaRequest,
 ) => {
      return orvalRequestor<CustomDataSchema>(
      {url: `/api/custom-data-schema`, method: 'post',
      data: saveCustomDataSchemaRequest
    },
      );
    }
  


    export const usePostApiCustomDataSchema = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiCustomDataSchema>, TError,{data: SaveCustomDataSchemaRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiCustomDataSchema>, {data: SaveCustomDataSchemaRequest}> = (props) => {
          const {data} = props || {};

          return  postApiCustomDataSchema(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiCustomDataSchema>, TError, {data: SaveCustomDataSchemaRequest}, TContext>(mutationFn, mutationOptions)
    }
    