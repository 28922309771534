/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Player,
  SavePlayerRequest,
  PlayerViewModel,
  PlayerIPagedResultList,
  GetApiPlayersParams,
  EntitySearch,
  ObjectIPagedResultList,
  BulkPlayerOperationResponse,
  BulkCreatePlayersRequest,
  BulkGrantCurrencyRequest,
  BulkGrantItemRequest,
  BulkGrantItemsToPlayerResponse,
  BulkGrantItemsToPlayerRequest,
  TrackBulkGrantItemsToPlayerResponse,
  CreateStudioPlayerRequest,
  VendJwtResponse,
  GetApiPlayersIdJwtParams
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiPlayersId = (
    id: string,
 ) => {
      return orvalRequestor<Player>(
      {url: `/api/players/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiPlayersIdQueryKey = (id: string,) => [`/api/players/${id}`];

    
export const useGetApiPlayersId = <TData = AsyncReturnType<typeof getApiPlayersId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPlayersId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPlayersIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPlayersId>> = () => getApiPlayersId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiPlayersId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiPlayersId = (
    id: string,
    savePlayerRequest: SavePlayerRequest,
 ) => {
      return orvalRequestor<Player>(
      {url: `/api/players/${id}`, method: 'put',
      data: savePlayerRequest
    },
      );
    }
  


    export const usePutApiPlayersId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiPlayersId>, TError,{id: string;data: SavePlayerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiPlayersId>, {id: string;data: SavePlayerRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiPlayersId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiPlayersId>, TError, {id: string;data: SavePlayerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayersId = (
    id: string,
    playerViewModel: PlayerViewModel,
 ) => {
      return orvalRequestor<Player>(
      {url: `/api/players/${id}`, method: 'post',
      data: playerViewModel
    },
      );
    }
  


    export const usePostApiPlayersId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayersId>, TError,{id: string;data: PlayerViewModel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayersId>, {id: string;data: PlayerViewModel}> = (props) => {
          const {id,data} = props || {};

          return  postApiPlayersId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayersId>, TError, {id: string;data: PlayerViewModel}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiPlayers = (
    params?: GetApiPlayersParams,
 ) => {
      return orvalRequestor<PlayerIPagedResultList>(
      {url: `/api/players`, method: 'get',
        params,
    },
      );
    }
  

export const getGetApiPlayersQueryKey = (params?: GetApiPlayersParams,) => [`/api/players`, ...(params ? [params]: [])];

    
export const useGetApiPlayers = <TData = AsyncReturnType<typeof getApiPlayers>, TError = unknown>(
 params?: GetApiPlayersParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPlayers>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPlayersQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPlayers>> = () => getApiPlayers(params, );

  const query = useQuery<AsyncReturnType<typeof getApiPlayers>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiPlayers = (
    playerViewModel: PlayerViewModel,
 ) => {
      return orvalRequestor<Player>(
      {url: `/api/players`, method: 'post',
      data: playerViewModel
    },
      );
    }
  


    export const usePostApiPlayers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayers>, TError,{data: PlayerViewModel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayers>, {data: PlayerViewModel}> = (props) => {
          const {data} = props || {};

          return  postApiPlayers(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayers>, TError, {data: PlayerViewModel}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayersFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<PlayerIPagedResultList>(
      {url: `/api/players/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiPlayersFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayersFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayersFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiPlayersFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayersFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayersQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/players/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiPlayersQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayersQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayersQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiPlayersQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayersQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayersBulkCreate = (
    bulkCreatePlayersRequest: BulkCreatePlayersRequest,
 ) => {
      return orvalRequestor<BulkPlayerOperationResponse>(
      {url: `/api/players/bulk-create`, method: 'post',
      data: bulkCreatePlayersRequest
    },
      );
    }
  


    export const usePostApiPlayersBulkCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayersBulkCreate>, TError,{data: BulkCreatePlayersRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayersBulkCreate>, {data: BulkCreatePlayersRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPlayersBulkCreate(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayersBulkCreate>, TError, {data: BulkCreatePlayersRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayersBulkGrantCurrency = (
    bulkGrantCurrencyRequest: BulkGrantCurrencyRequest,
 ) => {
      return orvalRequestor<BulkPlayerOperationResponse>(
      {url: `/api/players/bulk-grant-currency`, method: 'post',
      data: bulkGrantCurrencyRequest
    },
      );
    }
  


    export const usePostApiPlayersBulkGrantCurrency = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayersBulkGrantCurrency>, TError,{data: BulkGrantCurrencyRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayersBulkGrantCurrency>, {data: BulkGrantCurrencyRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPlayersBulkGrantCurrency(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayersBulkGrantCurrency>, TError, {data: BulkGrantCurrencyRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayersBulkGrantItem = (
    bulkGrantItemRequest: BulkGrantItemRequest,
 ) => {
      return orvalRequestor<BulkPlayerOperationResponse>(
      {url: `/api/players/bulk-grant-item`, method: 'post',
      data: bulkGrantItemRequest
    },
      );
    }
  


    export const usePostApiPlayersBulkGrantItem = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayersBulkGrantItem>, TError,{data: BulkGrantItemRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayersBulkGrantItem>, {data: BulkGrantItemRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPlayersBulkGrantItem(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayersBulkGrantItem>, TError, {data: BulkGrantItemRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiPlayersBulkGrantItems = (
    bulkGrantItemsToPlayerRequest: BulkGrantItemsToPlayerRequest,
 ) => {
      return orvalRequestor<BulkGrantItemsToPlayerResponse>(
      {url: `/api/players/bulk-grant-items`, method: 'post',
      data: bulkGrantItemsToPlayerRequest
    },
      );
    }
  


    export const usePostApiPlayersBulkGrantItems = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayersBulkGrantItems>, TError,{data: BulkGrantItemsToPlayerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayersBulkGrantItems>, {data: BulkGrantItemsToPlayerRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPlayersBulkGrantItems(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayersBulkGrantItems>, TError, {data: BulkGrantItemsToPlayerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiPlayersTrackBulkGrantItemsTrackingId = (
    trackingId: string,
 ) => {
      return orvalRequestor<TrackBulkGrantItemsToPlayerResponse>(
      {url: `/api/players/track-bulk-grant-items/${trackingId}`, method: 'get'
    },
      );
    }
  

export const getGetApiPlayersTrackBulkGrantItemsTrackingIdQueryKey = (trackingId: string,) => [`/api/players/track-bulk-grant-items/${trackingId}`];

    
export const useGetApiPlayersTrackBulkGrantItemsTrackingId = <TData = AsyncReturnType<typeof getApiPlayersTrackBulkGrantItemsTrackingId>, TError = unknown>(
 trackingId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPlayersTrackBulkGrantItemsTrackingId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPlayersTrackBulkGrantItemsTrackingIdQueryKey(trackingId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPlayersTrackBulkGrantItemsTrackingId>> = () => getApiPlayersTrackBulkGrantItemsTrackingId(trackingId, );

  const query = useQuery<AsyncReturnType<typeof getApiPlayersTrackBulkGrantItemsTrackingId>, TError, TData>(queryKey, queryFn, {enabled: !!(trackingId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiPlayersStudio = (
    createStudioPlayerRequest: CreateStudioPlayerRequest,
 ) => {
      return orvalRequestor<Player>(
      {url: `/api/players/studio`, method: 'post',
      data: createStudioPlayerRequest
    },
      );
    }
  


    export const usePostApiPlayersStudio = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiPlayersStudio>, TError,{data: CreateStudioPlayerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiPlayersStudio>, {data: CreateStudioPlayerRequest}> = (props) => {
          const {data} = props || {};

          return  postApiPlayersStudio(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiPlayersStudio>, TError, {data: CreateStudioPlayerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiPlayersIdJwt = (
    id: string,
    params?: GetApiPlayersIdJwtParams,
 ) => {
      return orvalRequestor<VendJwtResponse>(
      {url: `/api/players/${id}/jwt`, method: 'get',
        params,
    },
      );
    }
  

export const getGetApiPlayersIdJwtQueryKey = (id: string,
    params?: GetApiPlayersIdJwtParams,) => [`/api/players/${id}/jwt`, ...(params ? [params]: [])];

    
export const useGetApiPlayersIdJwt = <TData = AsyncReturnType<typeof getApiPlayersIdJwt>, TError = unknown>(
 id: string,
    params?: GetApiPlayersIdJwtParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiPlayersIdJwt>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiPlayersIdJwtQueryKey(id,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiPlayersIdJwt>> = () => getApiPlayersIdJwt(id,params, );

  const query = useQuery<AsyncReturnType<typeof getApiPlayersIdJwt>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

