/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  GameIPagedResultList,
  GetApiGamesParams,
  Game,
  SaveGameRequest,
  GameAllowedAudiences,
  SaveGameAllowedAudiencesRequest,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const getApiGames = (
    params?: GetApiGamesParams,
 ) => {
      return orvalRequestor<GameIPagedResultList>(
      {url: `/api/games`, method: 'get',
        params,
    },
      );
    }
  

export const getGetApiGamesQueryKey = (params?: GetApiGamesParams,) => [`/api/games`, ...(params ? [params]: [])];

    
export const useGetApiGames = <TData = AsyncReturnType<typeof getApiGames>, TError = unknown>(
 params?: GetApiGamesParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiGames>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiGamesQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiGames>> = () => getApiGames(params, );

  const query = useQuery<AsyncReturnType<typeof getApiGames>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const postApiGames = (
    saveGameRequest: SaveGameRequest,
 ) => {
      return orvalRequestor<Game>(
      {url: `/api/games`, method: 'post',
      data: saveGameRequest
    },
      );
    }
  


    export const usePostApiGames = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiGames>, TError,{data: SaveGameRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiGames>, {data: SaveGameRequest}> = (props) => {
          const {data} = props || {};

          return  postApiGames(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiGames>, TError, {data: SaveGameRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiGamesAudiencesId = (
    id: string,
 ) => {
      return orvalRequestor<GameAllowedAudiences>(
      {url: `/api/games/audiences/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiGamesAudiencesIdQueryKey = (id: string,) => [`/api/games/audiences/${id}`];

    
export const useGetApiGamesAudiencesId = <TData = AsyncReturnType<typeof getApiGamesAudiencesId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiGamesAudiencesId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiGamesAudiencesIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiGamesAudiencesId>> = () => getApiGamesAudiencesId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiGamesAudiencesId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const putApiGamesAudiencesId = (
    id: string,
    saveGameAllowedAudiencesRequest: SaveGameAllowedAudiencesRequest,
 ) => {
      return orvalRequestor<GameAllowedAudiences>(
      {url: `/api/games/audiences/${id}`, method: 'put',
      data: saveGameAllowedAudiencesRequest
    },
      );
    }
  


    export const usePutApiGamesAudiencesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiGamesAudiencesId>, TError,{id: string;data: SaveGameAllowedAudiencesRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiGamesAudiencesId>, {id: string;data: SaveGameAllowedAudiencesRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiGamesAudiencesId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiGamesAudiencesId>, TError, {id: string;data: SaveGameAllowedAudiencesRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiGamesId = (
    id: string,
    saveGameRequest: SaveGameRequest,
 ) => {
      return orvalRequestor<Game>(
      {url: `/api/games/${id}`, method: 'put',
      data: saveGameRequest
    },
      );
    }
  


    export const usePutApiGamesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiGamesId>, TError,{id: string;data: SaveGameRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiGamesId>, {id: string;data: SaveGameRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiGamesId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiGamesId>, TError, {id: string;data: SaveGameRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiGamesId = (
    id: string,
 ) => {
      return orvalRequestor<Game>(
      {url: `/api/games/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiGamesIdQueryKey = (id: string,) => [`/api/games/${id}`];

    
export const useGetApiGamesId = <TData = AsyncReturnType<typeof getApiGamesId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiGamesId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiGamesIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiGamesId>> = () => getApiGamesId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiGamesId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const deleteApiGamesId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/games/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiGamesId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiGamesId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiGamesId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiGamesId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiGamesId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiGamesFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<GameIPagedResultList>(
      {url: `/api/games/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiGamesFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiGamesFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiGamesFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiGamesFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiGamesFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiGamesQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/games/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiGamesQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiGamesQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiGamesQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiGamesQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiGamesQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    