/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Backoffice.Rest.Api-dev
 * OpenAPI spec version: 1.21.0.2517
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ShopTabContainer,
  SaveShopTabContainerRequest,
  ShopTabContainerIPagedResultList,
  EntitySearch,
  ObjectIPagedResultList
} from '.././model'
import { orvalRequestor } from '../../shared/core/GameServiceApiOrvalFacade'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postApiShopTabContainer = (
    saveShopTabContainerRequest: SaveShopTabContainerRequest,
 ) => {
      return orvalRequestor<ShopTabContainer>(
      {url: `/api/shop-tab-container`, method: 'post',
      data: saveShopTabContainerRequest
    },
      );
    }
  


    export const usePostApiShopTabContainer = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabContainer>, TError,{data: SaveShopTabContainerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabContainer>, {data: SaveShopTabContainerRequest}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabContainer(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabContainer>, TError, {data: SaveShopTabContainerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const putApiShopTabContainerId = (
    id: string,
    saveShopTabContainerRequest: SaveShopTabContainerRequest,
 ) => {
      return orvalRequestor<ShopTabContainer>(
      {url: `/api/shop-tab-container/${id}`, method: 'put',
      data: saveShopTabContainerRequest
    },
      );
    }
  


    export const usePutApiShopTabContainerId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof putApiShopTabContainerId>, TError,{id: string;data: SaveShopTabContainerRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof putApiShopTabContainerId>, {id: string;data: SaveShopTabContainerRequest}> = (props) => {
          const {id,data} = props || {};

          return  putApiShopTabContainerId(id,data,)
        }

      return useMutation<AsyncReturnType<typeof putApiShopTabContainerId>, TError, {id: string;data: SaveShopTabContainerRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteApiShopTabContainerId = (
    id: string,
 ) => {
      return orvalRequestor<void>(
      {url: `/api/shop-tab-container/${id}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteApiShopTabContainerId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteApiShopTabContainerId>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteApiShopTabContainerId>, {id: string}> = (props) => {
          const {id} = props || {};

          return  deleteApiShopTabContainerId(id,)
        }

      return useMutation<AsyncReturnType<typeof deleteApiShopTabContainerId>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    export const getApiShopTabContainerId = (
    id: string,
 ) => {
      return orvalRequestor<ShopTabContainer>(
      {url: `/api/shop-tab-container/${id}`, method: 'get'
    },
      );
    }
  

export const getGetApiShopTabContainerIdQueryKey = (id: string,) => [`/api/shop-tab-container/${id}`];

    
export const useGetApiShopTabContainerId = <TData = AsyncReturnType<typeof getApiShopTabContainerId>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getApiShopTabContainerId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetApiShopTabContainerIdQueryKey(id);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getApiShopTabContainerId>> = () => getApiShopTabContainerId(id, );

  const query = useQuery<AsyncReturnType<typeof getApiShopTabContainerId>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const postApiShopTabContainerFindBy = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ShopTabContainerIPagedResultList>(
      {url: `/api/shop-tab-container/find-by`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopTabContainerFindBy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabContainerFindBy>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabContainerFindBy>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabContainerFindBy(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabContainerFindBy>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    export const postApiShopTabContainerQuery = (
    entitySearch: EntitySearch,
 ) => {
      return orvalRequestor<ObjectIPagedResultList>(
      {url: `/api/shop-tab-container/query`, method: 'post',
      data: entitySearch
    },
      );
    }
  


    export const usePostApiShopTabContainerQuery = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postApiShopTabContainerQuery>, TError,{data: EntitySearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postApiShopTabContainerQuery>, {data: EntitySearch}> = (props) => {
          const {data} = props || {};

          return  postApiShopTabContainerQuery(data,)
        }

      return useMutation<AsyncReturnType<typeof postApiShopTabContainerQuery>, TError, {data: EntitySearch}, TContext>(mutationFn, mutationOptions)
    }
    